<template>
  <div class="page-classroom-view">
    <div class="my-page">
      <van-form style="background-color: transparent;">
        <div class="my-card">
          <van-field
            readonly
            required
            v-model="viewData.CreateUserName"
            name="CreateUserName"
            label="加班申请人"
          />
          <van-field
            readonly
            required
            v-model="viewData.FlowIDName"
            name="FlowIDName"
            label="审核流程"
          />
          <van-field
            readonly
            required
            v-model="viewData.BeginDate"
            name="BeginDate"
            label="加班开始时间"
          />
          <van-field
            readonly
            required
            v-model="viewData.EndDate"
            name="EndDate"
            label="加班结束时间"
          />
          <van-field
            readonly
            required
            type="number"
            v-model="viewData.OverTimeDays"
            name="OverTimeDays"
            label="加班天数"
          />
          <van-field
            readonly
            required
            v-model="viewData.OverTimeDesc"
            name="OverTimeDesc"
            rows="2"
            label="加班事由"
            type="textarea"
          />
          <van-field name="AccessoryPostList" label="附件">
            <template #input>
              <van-uploader
                readonly
                v-model="viewData.AccessoryPostList"
                :deletable="false"
                :preview-full-image="false"
                accept="*"
                :before-read="beforeRead"
                @click-preview="review"
              />
            </template>
          </van-field>
        </div>
      </van-form>
      <div class="my-card">
        <van-collapse v-model="activeNames">
          <van-collapse-item title="审核流程" name="1">
            <van-steps
              direction="vertical"
              :active="flowList.length > 0 ? flowList.length - 1 : 0"
              active-color="#ffe500"
            >
              <van-step v-for="(item, i) in flowList" :key="i">
                <div class="flow-item">
                  <div class="flow-top">
                    <van-tag
                      v-if="
                        item.AuditStateName === '未审核' ||
                          item.AuditStateName === '驳回'
                      "
                      color="#f5222d"
                      type="primary"
                      size="large"
                      plain
                    >
                      {{ item.AuditStateName }}
                    </van-tag>
                    <van-tag
                      v-if="item.AuditStateName === '通过'"
                      color="#1890ff"
                      type="primary"
                      size="large"
                      plain
                    >
                      {{ item.AuditStateName }}
                    </van-tag>
                    <span class="name">{{ item.AuditUserName }}</span>
                    <span class="time">{{ item.AuditDateStr }}</span>
                  </div>
                  <div class="flow-bottom">审核意见：{{ item.AuditDesc }}</div>
                </div>
              </van-step>
            </van-steps>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Form,
  Field,
  Uploader,
  Popup,
  Picker,
  DatetimePicker,
  Collapse,
  CollapseItem,
  Step,
  Steps,
  Tag,
  Toast
} from 'vant'
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Tag.name]: Tag,
    [Toast.name]: Toast
  },
  data () {
    return {
      minDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      id: null,
      viewData: {
        AccessoryPostList: []
      },
      flowList: [],
      leaveTypeList: [],
      auditFlowList: [],
      showPicker: false,
      showPicker2: false,
      showTime: false,
      showTime2: false,
      loading: false,
      activeNames: ['1']
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getDetail()
      // 请求审核流程
      this.getFlow()
    }
  },
  methods: {
    getDetail () {
      this.$axios
        .get('/api/OverTime/Get', {
          ID: this.id
        })
        .then(res => {
          if (res.code === 200) {
            this.viewData = {
              ...res.data,
              AccessoryPostList: res.data.accessoryList
            }
          } else {
            this.$toast.fail(res.msg || '请求异常')
            return false
          }
        })
    },
    getFlow () {
      // 请求审核流程
      this.$axios
        .get('/api/Auidt/AuditInfo', {
          ID: this.id
        })
        .then(res => {
          if (res.code === 200) {
            this.flowList = res.data
          }
        })
    },
    beforeRead (file) {
      Toast.loading({
        message: '加载中...',
        duration: 0
      })
      let formData = new FormData()
      formData.append('LastFile', file)
      formData.append('FileName', file.name)
      this.$axios.upload('/api/File/UploadFile', formData).then(res => {
        Toast.clear()
        if (res.code === 200) {
          this.viewData.AccessoryPostList.push({
            name: file.name,
            osskey: res.data.osskey,
            uploadUrl: res.data.uploadUrl,
            url: res.data.url
          })
          return true
        } else {
          this.$toast.fail(res.msg || '操作失败')
          return false
        }
      })
    },
    review (file) {
      if (file.url) {
        window.open(file.url)
      }
    },
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
